import React, { Fragment } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { baseUrl } from "../../api/Api";
import parser from 'html-react-parser'

const DetailsBody = ({singlePost}) =>{
    const photoUrl = `${baseUrl}/${singlePost.featured_image}`;
    return(
        <Fragment>
            <Container className="mt-5">
              <div className="detail_body">
              <div className="detailBody_image">
                  <img src={photoUrl} alt={singlePost.title} width='100%' />
              </div>
               <div className="detail_body_data">
                     <div className="sectionHeader">
                        <h4>{singlePost.title}</h4>
                    </div>
                    <div className="detail_content">
                      {parser(singlePost.body) }
                    </div>
               </div>
              </div>
            </Container>
        </Fragment>
    )
}
export default  DetailsBody;
