import { faBehance, faFacebook, faInstagram, faLinkedin, faTwitter } from "@fortawesome/free-brands-svg-icons";
import { faAddressCard, faAngleRight, faCaretRight, faEnvelope, faLocation, faPhone } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { Fragment } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { barCodeUrl, baseUrl } from "../../api/Api";
import Logo from '../../asset/image/logo.png' 

const Footer = ({footerData, footerNav}) =>{
   const navigate = useNavigate()
   const logoUrl =  `${baseUrl}/${footerData.entity_logo}`

   const handleNavigate = (uuid) =>{
      navigate(`/details/${uuid}`)
      window.location.reload()
    } 

   var footerMenus='';
   var otherMenu =''
   if(footerNav){
    footerMenus =footerNav.map((data,key)=>{
     if(data.title){
      var menu = ''
      if(data.child_nav.length<1){
       return(
         <li onClick={(e)=>handleNavigate(data.uuid)}><Link  ><i><FontAwesomeIcon icon={faAngleRight} /></i> {data.title} </Link></li>
       )
        
      }else{
        return(
          <>
           <li onClick={(e)=>handleNavigate(data.uuid)}><Link >{data.title}
                   <span className="droppper"> <FontAwesomeIcon icon={faCaretRight}/> </span></Link>
                    <ul className="sub_down text-start">
                        {(data.child_nav.map((data,key)=>{
                                return(
                                 <li><Link > <i><FontAwesomeIcon icon={faAngleRight} /></i> {data.title} </Link></li>
                                )
                            }))}
                    </ul>
           </li>
          </>
        )
      }
     }
    })
   }

 
    return( 
        <Fragment>
            <div className="footer">
               <Container>
                  <div className="footer_wrapper">
                    <Row>
                         <Col lg={3}>
                            <div className="footer_side_one">
                                 <div className="footer_logo">
                                   <Link to='/'><img src={logoUrl} alt={footerData.name} /></Link> 
                                 </div>
                                 <p>{footerData.entity_details}</p>
                                 <div className="footer_icon">
                                     <ul>
                                        <li> <i><FontAwesomeIcon icon={faTwitter} />  </i> </li>
                                        <li> <i><FontAwesomeIcon icon={faLinkedin} /> </i> </li>
                                        <li> <i><FontAwesomeIcon icon={faInstagram}/> </i> </li>
                                        <li> <i><FontAwesomeIcon icon={faFacebook} /> </i> </li> 
                                     </ul>
                                 </div>
                            </div>
                         </Col>
                         <Col lg={3}>
                            <div className="footer_side_two">
                              <h4> QR Code </h4> 
                            </div>
                            <img className="qrCode" src={barCodeUrl} />
                         </Col>
                         <Col lg={3}>
                            <div className="footer_side_two">
                               <h4> Important links </h4> 
                                 <ul>
                                    {footerMenus}
                                 </ul>
                            </div>
                         </Col>
                         <Col lg={3}>
                           <div className="footer_side_two">
                               <h4> Contact </h4> 
                               <div className="info_1"><i><FontAwesomeIcon icon={faEnvelope} /></i> {footerData.entity_email} </div>
                               <div className="info_1"><i><FontAwesomeIcon icon={faPhone} /></i> {footerData.entity_phone} </div>
                               <div className="info_1"><i><FontAwesomeIcon icon={faLocation} /></i> {footerData.address} </div>
                           </div>
                         </Col>
                     </Row>
                  </div>
                  <div className="copy_right">
                     <h5> © Copyright iCircles. All Rights Reserved </h5>
                  </div>
               </Container>
            </div>
        </Fragment>
    )
}
export default  Footer;