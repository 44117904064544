import { Bars, Rings, ThreeDots } from "react-loader-spinner";

export default function MainLoader(){
    return(
        <div className='Loader'>
           <Rings
                height="120"
                width="120"
                color="#FFB100"
                radius="6"
                wrapperStyle={{}}
                wrapperClass=""
                visible={true}
                ariaLabel="rings-loading"
                />
    </div>
    )
}