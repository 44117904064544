import React, { Fragment, useEffect, useState } from 'react'
import Nav from 'react-bootstrap/Nav';
import headerLogo from '../../asset/image/logo.png'
import "../../asset/css/bootstrap.min.css";
import "../../asset/css/style.css";
import "../../asset/css/responsive.css";
import { Link, NavLink, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown, faCartShopping, faSearch } from '@fortawesome/free-solid-svg-icons';
import { baseUrl } from '../../api/Api';
const TopNaviation = ({title,info,navs}) => {
    const [stickyNav, setStickyNav] = useState(false);
    const logoUrl =  `${baseUrl}/${info.entity_logo}`
    const navigate = useNavigate()
    useEffect(() => {
        window.onscroll = () => {
          setStickyNav(window.pageYOffset === 0 ? false : true);
          return () => (window.onscroll = null);
        };
      }, []);

      const handleNavigate = (uuid) =>{
        navigate(`/details/${uuid}`)
        window.location.reload()
      } 
   var menus='';
   var otherMenu =''
   if(navs){
    menus =navs.map((data,key)=>{
     if(data.title){
      var menu = ''
      if(data.child_nav.length<1){
       return(
        <li onClick={(e)=>handleNavigate(data.uuid)} ><Link >{data.title}</Link></li>
       )
        
      }else{
        return(
          <>
           <li><a href="/">{data.title}
                   <span className="droppper"> <FontAwesomeIcon icon={faCaretDown}/> </span></a>
                    <ul className="sub_down text-start">
                        {(data.child_nav.map((data,key)=>{
                                return(
                                <li onClick={(e)=>handleNavigate(data.uuid)} ><Link  > {data.title} </Link></li>
                                )
                            }))}
                    </ul>
           </li>
          </>
        )
      }
     }
    })
   }
     

return (
  <Fragment>
    <title>{title}</title>
       <header className='d-none d-lg-block'>
        <div className={` header ${stickyNav ? 'HeaderSticky' : ''} }`}>
                <div className="container">
                    <div className="navBar d-none d-lg-block col-md-block">
                        <div className="logo">
                            <Link to="/"><img src={logoUrl} alt={info.name} /></Link>
                        </div>
                        <div className="nav_item">
                            <ul>
                                <li><a href="/"> Home</a></li>
                                 {menus}
                                 <li> <Link to="/contact">Contact</Link> </li>
                            </ul>
                        </div>
                        <div className="nav_button">
                            <a className="get_start_btn" href="https://icircles.app/app/login">
                              Get Started
                            </a>
                        </div>
                    </div>
                </div>
            </div>
       </header>
  </Fragment>
)
}

export default TopNaviation