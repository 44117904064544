import React, { Fragment } from 'react'
import{ Routes, Route} from 'react-router-dom';
import ContactPage from '../pages/ContactPage';
import DetailsPage from '../pages/DetailsPage';
import HomePage from '../pages/HomePage';
import NotFoundPage from '../pages/NotFoundPage';

const AppRouter = () => {
  return (
    <Fragment>
      <Routes >
          <Route path='*' element={<NotFoundPage />} />
          <Route exact path="/" element={<HomePage />}/>
          <Route exact path="details/:uuid" element={<DetailsPage/>}/>
          <Route exact path="contact" element={<ContactPage/>}/>
      </Routes>
    </Fragment>
  )
}

export default AppRouter