import { Fragment } from 'react';
import { Col, Container, FloatingLabel, Row } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import contact from '../../asset/image/contact.png' 


const ContactBody = () =>{

    return(
      <Fragment>
          <Container>
           <div className="contact_body">
               <div className="contact_container">
                    <Row className='align-items-center'>
                       <Col lg={5}>
                         <div className="contact_left">
                             <img src={contact} alt="" />
                         </div>
                       </Col>
                       <Col lg={7}>
                         <div className="contact_right">
                            <h5> Contact with us</h5> 
                            <h6>24/7 we will answer your questions and problems</h6>
                            <div className="contact_form mt-4">
                              <Form>
                                <Form.Group className="mb-3" controlId="formBasicEmail">
                                  <Form.Label>Your Name</Form.Label>
                                  <Form.Control type="text" placeholder="Name" /> 
                                </Form.Group>
                                <Form.Group className="mb-3" controlId="formBasicEmail">
                                  <Form.Label>Email address</Form.Label>
                                  <Form.Control type="email" placeholder="Enter email" /> 
                                </Form.Group>
                                <Form.Group className="mb-3" controlId="formBasicEmail">
                                  <Form.Label>Phone Number</Form.Label>
                                  <Form.Control type="number" placeholder="Phone" /> 
                                </Form.Group>
                                <FloatingLabel className="mb-3" controlId="floatingTextarea2" label="Comments">
                                   <Form.Control
                                     as="textarea"
                                     placeholder="Leave a comment here"
                                     style={{ height: '100px' }}
                                   />
                                 </FloatingLabel>
                                <Form.Group className="mb-3" controlId="formBasicCheckbox">
                                  <Form.Check type="checkbox" label="Check me out" />
                                </Form.Group>
                                <Button variant="primary" type="submit">
                                  Submit
                                </Button>
                              </Form>
                            </div>
                         </div>
                       </Col>
                    </Row>
               </div>
             </div>
          </Container>
      </Fragment>
    )
}
export default  ContactBody;