import React, { Fragment } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { baseUrl } from "../../api/Api";
import feature1 from '../../asset/image/55.png'

const Feature = ({cards}) =>{
    return(
        <Fragment>
            <Container>
                <div className="feature_wrapper">
                   <div className="sectionHeader">
                        <h4>Built on Modern Web Technologies</h4>
                    </div>
                    <Row> 
                        {cards.map((data,key)=>{
                            const photoUrl = `${baseUrl}/${data.featured_image}`;
                            const detailsFilter = data.body.replace(/<[^>]*>?/gm, '').slice(0,100)
                            const details = detailsFilter.replace(/\&nbsp;/g, '');
                            return(
                                
                                    <Col sm={6} md={6} lg={3}> 
                                        <Link to={`/details/${data.uuid}`} >
                                            <div className="feature_wrapper">
                                            <div className="circle ">
                                                <img src={photoUrl} alt={data.title} />
                                            </div>
                                            <div className="feature_text">
                                                <h4>{data.title}</h4>
                                                <p>{`${details} ...`}</p>
                                            </div>
                                        </div>
                                        </Link>
                                    </Col>
                               
                                
                            )
                        })}
                    </Row>
                </div>
            </Container>
        </Fragment>
    )
}
export default  Feature;