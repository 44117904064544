import React, { Fragment } from "react";
import error from "../../asset/image/404.gif";
import bannerImg1 from "../../asset/image/software.png";
import bannerImg2 from "../../asset/image/s1.png";
import bannerImg3 from "../../asset/image/s2.png";
import bannerImg4 from "../../asset/image/avtar_testimonial.png";

import company1 from "../../asset/image/a.png";
import company2 from "../../asset/image/b.png";
import company3 from "../../asset/image/c.png";
import company4 from "../../asset/image/d.png";
import company5 from "../../asset/image/e.png";

import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Autoplay } from "swiper";
import { baseUrl, siteUrl } from "../../api/Api";
import { ReactSession } from "react-client-session";
import { Link } from "react-router-dom";

// import required modules
const BannerBody = ({ sliders,exclusive }) => {
  const Uuid = ReactSession.get("FrontPage").microsite_info.uuid;

  return (
    <Fragment>
      <div className="banner_main">
        <div className="container">
          <Swiper
            slidesPerView={1}
            spaceBetween={0}
            centeredSlides={true}
            autoplay={{
              delay: 10000,
              disableOnInteraction: false,
            }}
            loop={true}
            pagination={{
              clickable: true,
            }}
            navigation={false}
            modules={[Autoplay]}
            className="mySwiper"
          >
            {sliders.map((data, key) => {
              const photoUrl = `${baseUrl}/${data.featured_image}`;
              const moreUrl = `${siteUrl}/${Uuid}`;
              const detailsFilter = data.body.replace(/<[^>]*>?/gm, '').slice(0,350)
              const details = detailsFilter.replace(/\&nbsp;/g, '');
              return (
                <SwiperSlide key={data.id}>
                  <div className="banner ">
                    <div className="bannerText">
                      <h3> {data.title} </h3>
                      <p>{`${details} ...`}</p>
                      <Link to={`/details/${data.uuid}`} >
                      <div className="homeBtn">
                        <a href={moreUrl}>More</a>
                      </div>
                      </Link>
                      
                    </div>
                    <div className="bannerImg">
                      <img src={photoUrl} alt={data.title} />
                    </div>
                  </div>
                </SwiperSlide>
              );
            })}
          </Swiper>
        </div>
        <div className="container">
          <div className="client">
            <div className="row">
              <div className="col-lg-1"></div>
              <div className="col-lg-10">
                <div className="clientMain">
                  <Swiper
                    slidesPerView={3}
                    spaceBetween={0}
                    centeredSlides={true}
                    autoplay={{
                      delay: 2500,
                      disableOnInteraction: false,
                    }}
                    loop={true}
                    pagination={{
                      clickable: true,
                    }}
                    navigation={false}
                    modules={[Autoplay]}
                    className="mySwiper"
                  >
                    {exclusive.map((data,key)=>{
                      const photoUrl = `${baseUrl}/${data.featured_image}`;
                      return(
                        <SwiperSlide key={data.id}>
                          {" "}
                          <img src={photoUrl} alt={data.title} />
                        </SwiperSlide>
                      )
                    })}
                  </Swiper>
                </div>
              </div>
              <div className="col-lg-1"></div>
            </div>
          </div>
        </div>
        <div className="top_design_1"> </div>
        <div className="bottom_design_2"></div>
      </div>
    </Fragment>
  );
};
export default BannerBody;
