import axios from "axios";
import React, { Fragment, useEffect, useState } from "react";
import { getFrontPageUrl } from "../api/Api";
import ContactBody from "../components/ContactBody/ContactBody";
import MobileHeader from "../components/MobileHeader/MobileHeader";
import TopNaviation from "../components/TopNavigation/TopNaviation";
import { ReactSession } from 'react-client-session'
import { MainLoader } from "../components/Loader";
import Footer from "../components/Footer/Footer";

const ContactPage = () =>{
    ReactSession.setStoreType("localStorage");
    window.scroll(0,0)
    const FrontPage = ReactSession.get('FrontPage')
    const[main,setMain] = useState((FrontPage)?(FrontPage):'')
   
      useEffect(()=>{
        window.scroll(0,0)
      },)
  
      useEffect(()=>{
        if(FrontPage){
          setMain(FrontPage)
          axios.get(getFrontPageUrl)
          .then((res)=>{
            setMain(res.data)
            ReactSession.set("FrontPage",res.data);
          })
        }else{
          axios.get(getFrontPageUrl)
          .then((res)=>{
            setMain(res.data)
            ReactSession.set("FrontPage",res.data);
          })
          .catch(function(error){  
           
          })
        }
      },[])

      if(main){
        return(
            <Fragment>
                <MobileHeader info={main.microsite_info} navs={main.main_nav} title="Contact"/>
                <div className="detailPageHeader">
                   <TopNaviation info={main.microsite_info} navs={main.main_nav} title="Contact"/>
                </div>
                <ContactBody/>
                <Footer footerData={main.microsite_info} footerNav={main.footer_nav} />
            </Fragment>
        )
      }else{
        return(
          <>{(MainLoader())}</>
        )
      }


   
}

export default ContactPage