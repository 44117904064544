import { faBars, faCaretDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { Fragment, useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import logo from '../../asset/image/logo.png'

import Offcanvas from 'react-bootstrap/Offcanvas';
import { Link, useNavigate } from "react-router-dom";
import { baseUrl } from "../../api/Api";
const MobileHeader = ({title,info,navs}) =>{
  const navigate = useNavigate()
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [stickyNav, setStickyNav] = useState(false);
  const logoUrl =  `${baseUrl}/${info.entity_logo}`
 
  useEffect(() => {
      window.onscroll = () => {
        setStickyNav(window.pageYOffset === 0 ? false : true);
        return () => (window.onscroll = null);
      };
    }, []);

    const handleNavigate = (uuid) =>{
      navigate(`/details/${uuid}`)
      window.location.reload()
    } 
 var menus='';
 var otherMenu =''
 if(navs){
  menus =navs.map((data,key)=>{
   if(data.title){
    var menu = ''
    if(data.child_nav.length<1){
     return(
      <li onClick={(e)=>handleNavigate(data.uuid)} ><Link >{data.title}</Link></li>
     )
      
    }else{
      return(
        <>
         <li><a href="/">{data.title}
                 </a>
                 
         </li>
         {(data.child_nav.map((data,key)=>{
                              return(
                              <li onClick={(e)=>handleNavigate(data.uuid)} ><Link  > {data.title} </Link></li>
                              )
                          }))}
        </>
      )
    }
   }
  })
 }

    return(
        <Fragment>
            <Container>
                <div className="mobile_header d-block d-lg-none">
                   <div className="headerTop_wrapper">
                      <Link to="/">
                          <div className="logo"> 
                             <img src={logo} alt="" />
                          </div>
                      </Link>
                       
                       <div className="headerTop_right">
                            <i onClick={handleShow}> <FontAwesomeIcon icon={faBars}/> </i>
                       </div>   
                   </div>
                </div>
            </Container>
{/* OFcanvac  */}
    <Offcanvas show={show} onHide={handleClose}>
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>
               <div className="mobile_logo">
                   <Link className="logo">
                       <img src={logo} alt="" />
                  </Link>
               </div>
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
             <div className="nav_item">
                <ul>
                    <li><a href="/"> Home</a></li>
                    {menus}
                     <li> <Link to="/contact">Contact</Link> </li>
                </ul>
            </div>
            <div className="nav_button">
                            <a className="get_start_btn" href="https://icircles.app/app/login">
                              Get Started
                            </a>
                        </div>
        </Offcanvas.Body>
    </Offcanvas>
        </Fragment>
    )
}
export default  MobileHeader;