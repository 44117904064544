import React, { Fragment, useCallback } from "react";
import Footer from "../components/Footer/Footer";
import TopNaviation from "../components/TopNavigation/TopNaviation";
import { ReactSession } from 'react-client-session'
import axios from 'axios'
import { detailsPostUrl, getFrontPageUrl } from '../api/Api'
import { MainLoader } from '../components/Loader'
import { useEffect } from "react";
import { useState } from "react";
import DetailsBody from "../components/DetailBody/DetailsBody";
import useIsMountedRef from '../hooks/useIsMountedRef'
import MobileHeader from '../components/MobileHeader/MobileHeader'

const DetailsPage = () =>{
  const isMountedRef = useIsMountedRef();
    ReactSession.setStoreType("localStorage");
    window.scroll(0,0)
    const FrontPage = ReactSession.get('FrontPage')
    const[main,setMain] = useState((FrontPage)?(FrontPage):'')
    const[singlePost,setSinglePost] = useState({})

    var URL=window.location.pathname;
    var arr=URL.split('/');
    const uuid = arr[2]
   
  
      useEffect(()=>{
        window.scroll(0,0)
      },)
  
      useEffect(()=>{
        if(FrontPage){
          setMain(FrontPage)
        }else{
          axios.get(getFrontPageUrl)
          .then((res)=>{
            setMain(res.data)
            ReactSession.set("FrontPage",res.data);
          })
          .catch(function(error){  
           
          })
        }
      },[])

      const url = `${detailsPostUrl}/${uuid}`
      const getAllDetails = useCallback(async () => {
            try {
            const response = await axios.get(url);
            if (isMountedRef.current) {
                setSinglePost(response.data.posts);
            }
            } catch (error) {
            }
        }, [useIsMountedRef]);

        useEffect(() => {
            getAllDetails();
        }, [getAllDetails]);

  
      if(main && Object.keys(singlePost).length>0){
        return (
            <Fragment>
                <MobileHeader info={main.microsite_info} navs={main.main_nav} title="Details"/>
                <div className="detailPageHeader">
                   <TopNaviation info={main.microsite_info} navs={main.main_nav} title="Details"/>
                </div>
                <DetailsBody singlePost={singlePost} />
                <Footer footerData={main.microsite_info} footerNav={main.footer_nav} />
          </Fragment>
    )
      }else{
        return(
          <>{(MainLoader())}</>
        )
      }
  }
export default DetailsPage