import React, {Fragment, useEffect } from 'react'
import { useState } from 'react'
import Appointment from '../components/Appointment/Appointment'
import BannerBody from '../components/BannerBody/BannerBody'
import Feature from '../components/Feature/Feature'
import Footer from '../components/Footer/Footer'
import Stack from '../components/Stack/Stack'
import ThumbOne from '../components/ThumbOne/ThumbOne'
import TopNaviation from '../components/TopNavigation/TopNaviation'
import { ReactSession } from 'react-client-session'
import axios from 'axios'
import { getFrontPageUrl } from '../api/Api'
import { MainLoader } from '../components/Loader'
import MobileHeader from '../components/MobileHeader/MobileHeader'
import ThumbOnee from '../components/ThumbOne/ThumbOne'

const HomePage = () =>{
  ReactSession.setStoreType("localStorage");
  window.scroll(0,0)
  const FrontPage = ReactSession.get('FrontPage')
  const[main,setMain] = useState((FrontPage)?(FrontPage):'')
 
    useEffect(()=>{
      window.scroll(0,0)
    },)

    useEffect(()=>{
      if(FrontPage){
        setMain(FrontPage)
        axios.get(getFrontPageUrl)
        .then((res)=>{
          setMain(res.data)
          ReactSession.set("FrontPage",res.data);
        })
      }else{
        axios.get(getFrontPageUrl)
        .then((res)=>{
          setMain(res.data)
          ReactSession.set("FrontPage",res.data);
        })
        .catch(function(error){  
         
        })
      }
    },[])

    if(main){
      return (
          <Fragment>
            <MobileHeader info={main.microsite_info} navs={main.main_nav} title="Home"/>
            <TopNaviation info={main.microsite_info} navs={main.main_nav} title="Home"/>
            <BannerBody sliders={main.slider} exclusive={main.exclusive}/>
            <Feature cards={main.frontsections}/>
            <Footer footerData={main.microsite_info} footerNav={main.footer_nav} />
        </Fragment>
  )
    }else{
      return(
        <>{(MainLoader())}</>
      )
    }
}
export default HomePage